/* eslint no-console: 0 */
import Vue from 'vue'
import router from '@/clients/router.js'
import store from '@/clients/store'
import api from '@/clients/api'
import sharedApi from '@/shared/api'
import Spinner from './shared/spinner.vue'
import Flasher from './flasher'
Vue.use(Flasher)
import StepIndicator from './clients/booking/step_indicator.vue'
import MobileSummaryWidget from './clients/booking/summary_widget/mobile.vue'
import DesktopSummaryWidget from './clients/booking/summary_widget/desktop.vue'

import Debug from './clients/booking/debug.vue'
import DetectLocation from '@/shared/detect_location.vue'
import ConfirmationModal from './clients/booking/confirmation_modal.vue'
import TermsModal from './clients/booking/terms_modal.vue'
import ClientTextInput from './shared/inputs/text.vue'
import Sidemenu from './shared/sidemenu.vue'
import Dropdown from '@/shared/inputs/select.vue'
import MyForm from './shared/base_form.vue'
import BookingForm from './shared/booking_form.vue'
import Intercom from './shared/intercom.vue'
import VueGtag from 'vue-gtag'
import i18n from '@/shared/i18n'
import { getBrowserLocale } from '@/utils'

Vue.use(VueGtag, {
    config: { id: 'AW-1019391135' }
});

import _ from 'underscore'
import $ from 'jquery'
import { mapActions, mapGetters, mapMutations } from 'vuex'

Vue.component('MyForm', MyForm)
Vue.component('BookingForm', BookingForm)
Vue.component('Spinner', Spinner)
Vue.component('StepIndicator', StepIndicator)
Vue.component('MobileSummaryWidget', MobileSummaryWidget)
Vue.component('DesktopSummaryWidget', DesktopSummaryWidget)
Vue.component('ClientTextInput', ClientTextInput)
Vue.component('Dropdown', Dropdown)

import updated_by_others from '@/shared/updated_by_others_mixin'
Vue.mixin(updated_by_others)

//var s = require('underscore.string')

const LoggedInNavbar = {
    template: `<div class="header-float">
      <nav id="st-trigger-effects" class="navbar navbar-toggleable-md navbar-light bg-faded">
        <button class="navbar-toggler navbar-toggler-right" type="button" @click="$root.toggleSidebar">
          <div class="burger bar-1"></div>
          <div class="burger bar-2"></div>
          <div class="burger bar-3"></div>
        </button>
      </nav>
    </div>`
}
const GuestNavBar = {
    props: ['phone', 'userIcon'],
    template: `<div class="header">
      <nav id="st-trigger-effects" class="navbar navbar-toggleable-md navbar-light bg-faded">
        <div class="header-logo">
          <router-link class="header-logo-in" to="/"></router-link>
        </div>
        <ul class="header-link hide">
          <li style="border-right: none">
            <a href="/login">
              <img class="icon-user" :src="userIcon" alt="User"> Sign In
            </a>
          </li>
          <li>{{phone}}</li>
        </ul>
      </nav>
    </div>`
}

/*
FAQ is disabled for now. When enabling, also remove the style from the Sign In LI tag above
          <li>
            <a href="/faq">
              <i class="fal fa-life-ring support-icon"></i> Faq
            </a>
          </li>
*/

import userIcon from '@images/user.png'

Vue.component('topheader', {
    functional: true,
    render(h, context) {
        const root = context.parent.$root
        const props = {
            props: {
                user: root.currentUser,
                phone: root.companyPhone,
                userIcon: root.userIcon
            }
        }
        if (props.props.user) {
            if (root.showMenu) {
                return h(LoggedInNavbar, props)
            }
        } else {
            return h(GuestNavBar, props)
        }
    }
})

import Interceptors from '@/interceptors.js'

//// we can't use inject for clients, because the injected bindings are not
//// reactive, so can't detect changes (eg when client logs in and logs out)
Vue.prototype.isStaff = false
Vue.prototype.isAdmin = false
Vue.prototype.isCleaner = false

Object.defineProperty(Vue.prototype, 'isClient', {
  get () {
    return store.getters['isClient']
  }
})

Object.defineProperty(Vue.prototype, 'activeUser', {
  get () {
    if (store.getters['isClient']) {
      return { id:store.getters['client/client_id'], class: 'Client', type: 'client' }
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        el: '#client',
        i18n,
        store,
        router,
        components: { Debug, ConfirmationModal, TermsModal, Sidemenu, Intercom, DetectLocation },
        created() {
            this.$i18n.locale = getBrowserLocale()

            sharedApi.getLocales(['cleaning_plans', 'shared.first_name', 'shared.last_name', 'shared.email', 'shared.phone', 'shared.address']).then(response => {
              for(var localeKey in response.data) {
                let translations = response.data[localeKey]
                this.$i18n.mergeLocaleMessage(localeKey, translations)
              }
            })
        },
        mounted() {
            if (Window.redirect_to) {
                router.push({ path: Window.redirect_to })
            }
        },
        data: {
            userIcon: userIcon,
        },
        computed: {
            ...mapGetters([
                'loading', 'isBooking', 'finishedBooking', 'indicatorMessage', 'showMenu',
                'showConfirmationModal', 'showAdvancedNotesModal', 'showTermsModal',
                'currentUser', 'isServiceAreaAdmin',
                'doubleBookingMessage', 'showDoubleBookingModal', 'sidebarOpen'
            ]),
        },
        methods: {
            ...mapActions(['toggleSidebar']),
            clearFlexible() {},
            onBookingInit() {},
            onPromoCodeChange(v) {
                this.$store.commit('reservation/SET_ATTR', { attr: 'promo_code', value: v })
            },
            onSavePromoCode() {
                this.$store.dispatch('reservation/getCouponInfo', { root: true }).then((response) => {
                }, (error) => {
                    this.$flasher.error(error)
                })
            },
            onRemovePromoCode() {
                this.$store.dispatch('reservation/removeCouponInfo', { root: true })
            },
            onStartPicked(formattedStart, start, status) {
                this.startPicked = formattedStart
                this.start = start
                this.status = status
                this.showModal = false
                this.clearFlexible()
                this.quoteOnly = false
            },
            onToggleModal() {
                this.showModal = !this.showModal
                if (this.showModal && !this.flexible) {
                    this.clearFlexible()
                }
                this.quoteOnly = false
            },
            onToggleQuoteOnly() {
                this.quoteOnly = !this.quoteOnly
                this.clearFlexible()
                if (this.quoteOnly) {
                    this.status = 'quote_only'
                    this.startPicked = ''
                    this.start = ''
                } else {
                    this.status = ''
                }
            },
            showErrors(errors) {
                /*
                        $.each(errors, function(index, value) {
                          if (index == 'double_booking') {
                            app.doubleBookingContent = value
                          }
                          $('input[name="client['+index+']"]').addClass('is-invalid').parent().append('<div class="invalid-feedback">' + value + '</div>')
                          $('select[name="client['+index+']"]').addClass('is-invalid').parent().append('<div class="invalid-feedback">' + value + '</div>')
                        });
                */
            },
            book_now() {},
            doneBooking() {
                router.push({ name: 'pick-property' })
            },
            booking_params() {
                return {
                    /*
                              client: {
                                start: this.start,
                                status: this.status,
                                address: $('#client_address').val(),
                                phone: $('#client_phone').val(),
                                state: $('#client_state').val(),
                                city: $('#client_city').val(),
                                zip_code: $('#client_zip_code').val(),
                                note: $('#client_note').val(),
                                someone_home:  app.someone_home,
                                promo_code: $('#client_promo_code'),
                              },
                    */
                    confirm: app.confirmed
                }
            },
            onConfirm() {
                app.confirmed = 1
                app.book_now()
            },
        }
    });
})
