<template></template>
<script>
import {
  mapGetters,
  mapState
} from 'vuex'
import Vue from 'vue'
import VueIntercom from 'vue-intercom'
Vue.use(VueIntercom, { appId: 'pbvkiamc' });

export default {
  data(){
    return {
      user: {
        id: null,
        name: null,
        email: null
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    client_id() {
      return this.$store.getters['client/client_id']
    },
    full_name() {
      return this.$store.getters['client/full_name']
    },
    email() {
      return this.$store.getters['client/email']
    },
    hmac() {
      return this.$store.getters['client/hmac']
    },
  },
  watch: {
    full_name(newValue, oldValue) {
      this.user.name = newValue
    },
    email(newValue, oldValue) {
      this.user.email = newValue
    },
    client_id(newValue, oldValue) {
      this.user.id = newValue
    },
    currentUser(newValue, oldValue) {
      this.showChat()
    }
  },
  methods: {
    showChat() {
      if (this.currentUser == 'client'){
        this.$intercom.boot({
          user_id: this.user.id,
          client_id: this.user.id,
          name: this.user.name,
          email: this.user.email,
          user_type: "Client",
          user_hash: this.hmac
        })
      } else {
        this.$intercom.boot({
          name: 'Guest',
        })
      }
    }
  },
  mounted() {
    this.showChat()
  }
}
</script>

